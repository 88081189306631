import { Box } from "@mui/material";
import React from "react";

function FooterComponent() {
    return (
        <Box>
            <Box component={"p"} sx={{ textAlign: "center", fontSize: 12, color: "#000000", marginBottom: "0px" }}>
                .legal A/S · VAT No.: DK40888888 · Denmark
            </Box>

            <Box
                component={"p"}
                sx={{ textAlign: "center", fontSize: 12, marginTop: "5px", "& > *": { marginLeft: "3px", marginRight: "3px", color: "#7284FA" } }}
            >
                <Box component={"a"} href="https://www.dotlegal.com" rel="noreferrer" target="_blank">
                    Web
                </Box>
                ·
                <Box component={"a"} href="mailto:hello@dotlegal.com" rel="noreferrer">
                    Email
                </Box>
                ·
                <Box component={"a"} href="mailto:support@dotlegal.com" rel="noreferrer">
                    Support
                </Box>
                ·
                <Box component={"a"} href="https://help.dotlegal.com/en/knowledge" rel="noreferrer" target="_blank">
                    Help Center
                </Box>
                ·
                <Box component={"a"} href="https://www.dotlegal.com/en/terms#Security" rel="noreferrer" target="_blank">
                    Security
                </Box>
                ·
                <Box component={"a"} href="https://www.dotlegal.com/en/terms" rel="noreferrer" target="_blank">
                    Terms
                </Box>
                ·
                <Box component={"a"} href="https://www.dotlegal.com/en/privacy-policy" rel="noreferrer" target="_blank">
                    Privacy
                </Box>
            </Box>
        </Box>
    );
}

export default FooterComponent;
