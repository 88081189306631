export interface PurchaseFormModel {
    companyId: string;
    productCustomerId: string;
    accountId: string;
    companyName: string;
    legalVATNumber: string;
    Address: string;
    postalCode: string;
    city: string;
    country: string | undefined;
    countryCode: string | undefined;
    fullName: string;
    email: string;
    language: string;
    totalPrice: number;
    numberOfLegalEntities: number;
    canPurchase: boolean;
    paymentMethod: PaymentMethod;
    plans: PlanCostModel[];
    planType: PlanType | null;
}

export enum PaymentMethod {
    Invoice = 0,
    Card = 1,
}

export interface PlanCostModel {
    costPerUnit: number;
    totalPrice: number;
    planType: PlanType;
}

export enum PlanType {
    PrivacyStandard = 100,
    PrivacyPro = 103,
    PrivacyResearch = 104,
    PrivacyStarter = 105,
    PrivacyEssential = 106,
}
