import { createSxStyles } from "../../common/createSxStyles";

export const usePurchaseConfirmationStyles = () => {
    return createSxStyles({
        contentContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
        },
    });
};
