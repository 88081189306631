import { useAuth } from "oidc-react";
import React from "react";
import { setExternalToken } from "../common/api/apiShared";
import { DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";

export interface IExternalUserLoaderProps {
    children: JSX.Element;
}

function ExternalUserLoader(props: IExternalUserLoaderProps) {
    const auth = useAuth();

    if (auth.userData) {
        setExternalToken(auth.userData.access_token);
    }
    return <React.Fragment>{auth.isLoading ? <DotLegalFullScreenSpinner /> : <React.Fragment>{props.children}</React.Fragment>}</React.Fragment>;
}

export default ExternalUserLoader;
