import React from "react";
import { BrowserRouter } from "react-router-dom";

export interface IRoutingProps {
    children: any;
}

function Routing(props: IRoutingProps) {
    return <BrowserRouter>{props.children}</BrowserRouter>;
}

export default Routing;
