import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ajaxQueue } from "./common/api/ajaxQueue";
import { useNavigate } from "react-router-dom";

export interface IQueryClientProviderCustomerAdminWebProps {
    children: any;
}

const queryClient = new QueryClient();

window.addEventListener("beforeunload", function (e) {
    if (queryClient.isMutating() || ajaxQueue().isItemsOnQueue()) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
    }
});

function QueryClientProviderCustomerAdminWeb(props: IQueryClientProviderCustomerAdminWebProps) {
    let navigate = useNavigate();

    queryClient.setDefaultOptions({
        mutations: {
            onError: async (error) => {
                await queryClient.cancelMutations();
                ajaxQueue().clear();
                navigate("/error");
            },
        },
        queries: {
            useErrorBoundary: true,
        },
    });

    return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
}

export default QueryClientProviderCustomerAdminWeb;
