import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useMainStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        main: {
            backgroundColor: "white",
            display: "flex",
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
            [theme.breakpoints.up("md")]: {
                flexDirection: "row",
            },
            height: "100%",
        },
    });
};
