import React from "react";
import { Trans } from "react-i18next";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useIsOnSmallScreen } from "../../common/hooks/mediaHooks";
import { useTranslation } from "../../localization/useTranslation";
import { usePurchaseStyles } from "../Purchase.styles";
import { usePurchaseConfirmationHook } from "./PurchaseConfirmation.hooks";
import { usePurchaseConfirmationStyles } from "./PurchaseConfirmation.styles";
import { Box } from "@mui/material";
import logo from "../../common/images/logo.png";
import FooterComponent from "../../common/components/footerComponent/FooterComponent";
import { DotLegalButton, DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";

function PurchaseConfirmation() {
    const isSmallScreen = useIsOnSmallScreen();
    const styles = usePurchaseStyles(isSmallScreen);
    const confirmationStyles = usePurchaseConfirmationStyles();
    const { translateString } = useTranslation();

    const returnToProduct = () => {
        window.location.href = new URLSearchParams(window.location.search).get("returnUrl")!;
    };

    const { data } = usePurchaseConfirmationHook();

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ padding: 3, paddingLeft: "36px" }}>
                <Box sx={{ height: 58 }}>
                    <Box component={"img"} src={logo} alt={logo}></Box>
                </Box>
            </Box>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                <Box sx={styles.container}>
                    <Box sx={styles.whiteBox}>
                        {data === undefined || data === false ? (
                            <DotLegalFullScreenSpinner />
                        ) : (
                            <Box sx={confirmationStyles.contentContainer}>
                                <DotLegalHeader headerStyle={"xxl"}>{translateString("purchaseCompletionHeader")}</DotLegalHeader>
                                <DotLegalHeader headerStyle={"small"} fontWeight={500}>
                                    {translateString("purchaseCompletionEmailConfirmation")}
                                </DotLegalHeader>
                                <DotLegalHeader headerStyle={"small"} fontWeight={500} marginBottom={8}>
                                    <Trans
                                        i18nKey={"callOrContactDotLegal"}
                                        components={{
                                            phone: <a href="tel:+4570270127">+45 7027 0127</a>,
                                            email: <a href="mailto:support@dotlegal.com">support@dotlegal.com</a>,
                                        }}
                                    />
                                </DotLegalHeader>

                                <DotLegalButton buttonType={"primary"} onClick={() => returnToProduct()}>
                                    {translateString("returnToProduct", { product: "Privacy" })}
                                </DotLegalButton>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box>
                    <FooterComponent />
                </Box>
            </Box>
        </Box>
    );
}

export default PurchaseConfirmation;
