import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./errorPage/ErrorPage";
import { useTranslation } from "./localization/useTranslation";

export interface IErrorRouterSwitch {
    children: any;
}

function ErrorRouterSwitch(props: IErrorRouterSwitch) {
    const { translateString } = useTranslation();

    return (
        <React.Fragment>
            <Routes>
                <Route path="/error" element={<ErrorPage errorMessage={translateString("unexpectedError")} />} />
                <Route path="/resource-not-found" element={<ErrorPage errorMessage={translateString("resourceNotFound")} />} />
                <Route path="/card-registration-not-confirmed" element={<ErrorPage errorMessage={translateString("cardNotRegisteredError")} />} />
                <Route path="*" element={props.children} />
            </Routes>
        </React.Fragment>
    );
}

export default ErrorRouterSwitch;
