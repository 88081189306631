import { useMediaQuery, useTheme, Breakpoint } from "@mui/material";
import { getBreakpointForMobile } from "../../CustomerAdminWebTheme";

export function useIsOnExtraSmallScreen() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.only("xs"), { noSsr: true });
}

export function useIsOnSmallScreen() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(getBreakpointForMobile()), { noSsr: true });
}

export function useIsOnMediumScreen() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("lg"), { noSsr: true });
}

export function useIsOnLargeScreen() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("xl"), { noSsr: true });
}

export function useIsBetweenSize(start: number | Breakpoint, end: number | Breakpoint) {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.between(start, end), { noSsr: true });
}
