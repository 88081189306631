let queuedRequests: Array<any> = [];

export function ajaxQueue() {
    const executeRequest = function () {
        const reqWithVars = queuedRequests[0];
        return reqWithVars
            .req(reqWithVars.vars)
            .then(function (d: any) {
                queuedRequests.shift();
                if (queuedRequests.length > 0) {
                    return executeRequest();
                }
                return d;
            })
            .catch(function (e: Error) {
                queuedRequests = [];
                throw e;
            });
    };

    return {
        addRequest: function <TVariables, TResponse>(req: (vars: TVariables) => Promise<TResponse>, vars: TVariables): Promise<TResponse> | undefined {
            queuedRequests.push({ req, vars });
            if (queuedRequests.length === 1) {
                return executeRequest();
            }
        },
        isItemsOnQueue: function () {
            return queuedRequests.length > 0;
        },
        clear() {
            queuedRequests = [];
        },
    };
}
