import BackngroundImage from "./BackgroundWithBlobs.svg";
import { createSxStyles } from "../common/createSxStyles";
import { useTheme } from "@mui/material";

export const usePurchaseStyles = (isSmallScreen: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            backgroundImage: `url(${BackngroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "102% 100%",
            height: "85%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        whiteBox: {
            backgroundColor: theme.customPalette.white,
            borderRadius: "25px",
            padding: "48px",
            width: isSmallScreen ? "100%" : 1200,
            height: isSmallScreen ? "100%" : "auto",
            boxShadow: "0px 3px 6px #00000029",
        },
        purchaseBox: {
            marginBottom: "110px",
        },
    });
};
