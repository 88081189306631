import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { validateEmail } from "../../common/emailValidator";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { PaymentMethod, PlanType, PurchaseFormModel } from "./PurchaseForm.types";

export function usePurchaseFormHooks() {
    let navigate = useNavigate();
    const productCustomerId = new URLSearchParams(window.location.search).get("productCustomerId");
    const lang = new URLSearchParams(window.location.search).get("lang") ?? "en";
    const returnUrl = new URLSearchParams(window.location.search).get("returnUrl");

    const [viewModel, setViewModel] = useState<PurchaseFormModel | undefined>(undefined);
    const [errors, setErrors] = useState({ emailError: "", requiredField: "" });

    const url = "/PrivacyPurchase/" + productCustomerId;
    let { isLoading, data } = useQuery(url, () => get<PurchaseFormModel>(url));
    const [selectedPlan, setSelectedPlan] = useState<PlanType>();
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        if (data) {
            let tempModel = { ...viewModel! };
            tempModel.companyName = data.companyName;
            tempModel.companyId = data.companyId;
            tempModel.productCustomerId = data.productCustomerId;
            tempModel.accountId = data.accountId;
            tempModel.numberOfLegalEntities = data.numberOfLegalEntities;
            tempModel.language = lang;
            tempModel.paymentMethod = PaymentMethod.Invoice;
            tempModel.plans = data.plans;
            tempModel.planType = data.planType;
            setViewModel(tempModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data && selectedPlan) {
            const plan = data.plans.find((x) => x.planType === selectedPlan);
            let tempModel = { ...viewModel! };
            if (plan) {
                setTotalPrice(plan.totalPrice);
                tempModel.planType = selectedPlan;
            } else {
                tempModel.planType = null;
            }
            setViewModel(tempModel);
        } else {
            setTotalPrice(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlan]);

    const { translateString } = useTranslation();

    const saveMutation = useMutation(purchase);
    const onPurchaseProduct = () => {
        saveMutation.mutateAsync(viewModel!, {
            onSuccess: (resp: Result<{ url: string }>) => {
                /* if (viewModel?.paymentMethod === PaymentMethod.Card) {
                    openPaymentTab(resp.value().url);
                } */
                let navigationUrl = `/purchase-confirmation?lang=${lang}&returnUrl=${returnUrl}&accountId=${viewModel?.accountId}`;
                navigate(navigationUrl);
            },
        });
    };

    return {
        viewModel,
        setViewModel,
        isLoading,
        errors,
        isSaving: saveMutation.isLoading,
        validatePurchaseFormForm,
        validateEmailAddress,
        data,
        selectedPlan,
        setSelectedPlan,
        totalPrice,
    };

    function purchase(viewModel: PurchaseFormModel) {
        return post<{ url: string }>("/PrivacyPurchase", viewModel);
    }

    function validateEmailAddress(emailAddress: string) {
        let tempErrors = { ...errors };
        if (validateEmail(emailAddress) === false) {
            tempErrors.emailError = translateString("emailInvalid");
            if (isNullOrWhitespace(errors.emailError)) {
                setErrors(tempErrors);
            }
        } else {
            tempErrors.emailError = "";
            if (tempErrors.emailError !== errors.emailError) {
                setErrors(tempErrors);
            }
        }
    }

    function validatePurchaseFormForm() {
        let formValid = true;

        // Could be initialized as en empty object of erros
        let tempErrors = { ...errors };

        if (
            isNullOrWhitespace(viewModel?.companyName) ||
            isNullOrWhitespace(viewModel?.Address) ||
            isNullOrWhitespace(viewModel?.city) ||
            isNullOrWhitespace(viewModel?.country) ||
            isNullOrWhitespace(viewModel?.fullName) ||
            isNullOrWhitespace(viewModel?.postalCode) ||
            !viewModel?.planType
        ) {
            tempErrors.requiredField = translateString("fieldMandatory");
            formValid = false;
        }

        if (isNullOrWhitespace(viewModel?.email) || validateEmail(viewModel?.email!) === false) {
            tempErrors.emailError = translateString("emailInvalid");
            formValid = false;
        }

        if (formValid) {
            onPurchaseProduct();
        } else {
            setErrors(tempErrors);
        }
    }
}
