import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useContentStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        content: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: theme.customPalette.white,
            borderRadius: theme.spacing(4),
            position: "relative",
        },
        breadCrumbs: {
            marginBottom: theme.spacing(2),
        },
    });
};
