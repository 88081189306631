import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const usePurchaseFormStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
        },
        recipentHeader: {
            alignSelf: "baseline",
        },
        recipientContainer: {
            marginTop: theme.spacing(7),
        },
        radioGroup: {
            alignSelf: "baseline",
        },
        error: {
            color: theme.palette.error.main,
        },
        planLink: {
            color: theme.palette.primary.main,
        },
    });
};
