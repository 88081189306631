import React from "react";
import Routing from "./Routing";
import RouterSwitch from "./RouterSwitch";
import { TranslationProvider } from "./localization/LocalizationContext";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import customerAdminWebTheme from "./CustomerAdminWebTheme";
import QueryClientProviderCustomerAdminWeb from "./QueryClientProviderCustomerAdminWeb";
import { setupLocalization } from "./localization/setupLocalization";
import Main from "./main/Main";
import CustomerAdminWebAuthProvider from "./auth/CustomerAdminWebAuthProvider";
import ExternalUserLoader from "./auth/ExternalUserLoader";
import ErrorRouterSwitch from "./ErrorRouterSwitch";

setupLocalization();

function App() {
    return (
        <React.StrictMode>
            <Routing>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={customerAdminWebTheme}>
                        <CssBaseline />
                        <QueryClientProviderCustomerAdminWeb>
                            <TranslationProvider>
                                <ErrorRouterSwitch>
                                    <CustomerAdminWebAuthProvider>
                                        <ExternalUserLoader>
                                            <Main>
                                                <RouterSwitch />
                                            </Main>
                                        </ExternalUserLoader>
                                    </CustomerAdminWebAuthProvider>
                                </ErrorRouterSwitch>
                            </TranslationProvider>
                        </QueryClientProviderCustomerAdminWeb>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Routing>
        </React.StrictMode>
    );
}

export default App;
