export interface CustomerAdminWebSettings {
    apiBaseUrl: string;
    mainSiteBaseUrl: string;
    idpAuthority: string;
    idpClientId: string;
}

export function getSettings(): CustomerAdminWebSettings {
    if (window.location.host.startsWith("localhost")) {
        return {
            apiBaseUrl: "https://localhost:7280",
            mainSiteBaseUrl: "http://localhost:3002",
            idpAuthority: "https://id-test.dotlegal.com",
            idpClientId: "CustomerAdminWebSPALocalhost",
        };
    } else {
        const isTest = window.location.host === getEnvVariable("REACT_APP_CUSTOMER_ADMIN_URL_TEST");
        if (isTest) {
            const apiUrl = "https://" + getEnvVariable("REACT_APP_CUSTOMER_ADMIN_URL_TEST");
            return {
                apiBaseUrl: apiUrl,
                mainSiteBaseUrl: apiUrl,
                idpAuthority: getEnvVariable("REACT_APP_IDP_AUTHORITY_TEST"),
                idpClientId: getEnvVariable("REACT_APP_IDP_CLIENT_ID_TEST"),
            };
        }
        const isProd = window.location.host === getEnvVariable("REACT_APP_CUSTOMER_ADMIN_URL_PROD");
        if (isProd) {
            const apiUrl = "https://" + getEnvVariable("REACT_APP_CUSTOMER_ADMIN_URL_PROD");
            return {
                apiBaseUrl: apiUrl,
                mainSiteBaseUrl: apiUrl,
                idpAuthority: getEnvVariable("REACT_APP_IDP_AUTHORITY_PROD"),
                idpClientId: getEnvVariable("REACT_APP_IDP_CLIENT_ID_PROD"),
            };
        }
        throw new Error("Unknown window.location.host: " + window.location.host);
    }
}

function getEnvVariable(key: string) {
    return process.env[key] ?? `No env variable matching ${key}`;
}
