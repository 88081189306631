import { useRef } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";

export function usePurchaseConfirmationHook() {
    const maxCounts = 90; // 90 is for 3 min. We wait 2 sec between poll.
    const accountId = new URLSearchParams(window.location.search).get("accountId");
    const counter = useRef(0);
    const url = "/PrivacyPurchase/" + accountId + "/ordercompleted";
    let { isLoading, data } = useQuery(url, () => get<boolean>(url), {
        refetchInterval: (data) => (counter.current < maxCounts && data !== true ? 2000 : false),
        refetchIntervalInBackground: true,
        onSuccess: (data) => {
            if (data === undefined || data === false) {
                counter.current++;
            }
        },
    });

    if (counter.current > maxCounts && (data === undefined || data === false)) {
        window.location.href = "/card-registration-not-confirmed";
    }

    return { isLoading, data };
}
