import React from "react";
import Content from "./content/Content";
import { useMainStyles } from "./Main.styles";
import { Box } from "@mui/material";

export interface IMainProps {
    children: JSX.Element;
}

function Main(props: IMainProps) {
    const styles = useMainStyles();

    return (
        <Box sx={styles.main}>
            <Content>{props.children}</Content>
        </Box>
    );
}

export default Main;
