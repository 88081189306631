import React from "react";
import { useIsOnSmallScreen } from "../common/hooks/mediaHooks";
import { usePurchaseStyles } from "./Purchase.styles";
import PurchaseForm from "./PurchaseForm/PuchaseForm";
import { Box } from "@mui/material";
import logo from "../common/images/logo.png";
import FooterComponent from "../common/components/footerComponent/FooterComponent";

function Purchase() {
    const isSmallScreen = useIsOnSmallScreen();
    const styles = usePurchaseStyles(isSmallScreen);

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ padding: 3, paddingLeft: "36px" }}>
                <Box sx={{ height: 58 }}>
                    <Box component={"img"} src={logo} alt={logo}></Box>
                </Box>
            </Box>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                <Box sx={styles.container}>
                    <Box sx={[styles.whiteBox, styles.purchaseBox]}>
                        <PurchaseForm />
                    </Box>
                </Box>
                <Box>
                    <FooterComponent />
                </Box>
            </Box>
        </Box>
    );
}

export default Purchase;
