import { Box, Grid, RadioGroup } from "@mui/material";
import React from "react";
import { DotLegalButton, DotLegalFullScreenSpinner, DotLegalRadioButton, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { usePurchaseFormStyles } from "./PurchaseForm.styles";
import { usePurchaseFormHooks } from "./PurchaseForm.hooks";
import { Trans } from "react-i18next";
import { PlanCostModel, PlanType } from "./PurchaseForm.types";

function PurchaseForm() {
    const styles = usePurchaseFormStyles();
    const { translateString, translateCountry } = useTranslation();
    const {
        viewModel,
        setViewModel,
        validatePurchaseFormForm,
        validateEmailAddress,
        errors,
        isSaving,
        isLoading,
        data,
        selectedPlan,
        setSelectedPlan,
        totalPrice,
    } = usePurchaseFormHooks();

    const getPlan = (plan: PlanCostModel): string => {
        if (plan.planType === PlanType.PrivacyEssential) {
            return `Essential (DKK ${plan.costPerUnit} ${translateString("unitsPerMonth")})`;
        }
        if (plan.planType === PlanType.PrivacyStandard) {
            return `Standard (DKK ${plan.costPerUnit} ${translateString("unitsPerMonth")})`;
        }
        if (plan.planType === PlanType.PrivacyPro) {
            return `Pro (DKK ${plan.costPerUnit} ${translateString("unitsPerMonth")})`;
        }
        return "";
    };

    return (
        <Box sx={styles.container}>
            {isLoading ? (
                <DotLegalFullScreenSpinner />
            ) : data?.canPurchase === false ? (
                <DotLegalHeader headerStyle="medium" fontWeight={500}>
                    <Trans i18nKey="alreadyHasPrivacy" values={{ product: "Privacy" }} />
                </DotLegalHeader>
            ) : (
                <>
                    <DotLegalHeader headerStyle={"xxl"} marginBottom={1}>
                        {translateString("purchaseProductHeader", { product: "Privacy" })}
                    </DotLegalHeader>

                    <Box
                        sx={(theme) => ({
                            "& svg": {
                                fill: `${theme.palette.primary.main} !important`,
                            },
                        })}
                    >
                        <RadioGroup
                            row
                            value={selectedPlan}
                            onChange={(e) => {
                                setSelectedPlan(Number((e.target as HTMLInputElement).value));
                            }}
                        >
                            {data?.plans
                                .sort((x, y) => x.costPerUnit - y.costPerUnit)
                                .map((x) => (
                                    <div key={x.planType}>
                                        <DotLegalRadioButton value={x.planType} smallSideMargin label={getPlan(x)} />
                                    </div>
                                ))}
                        </RadioGroup>
                    </Box>

                    <Box sx={{ visibility: viewModel?.planType ? "visible" : "hidden" }}>
                        <DotLegalHeader headerStyle={"small"} marginBottom={errors.requiredField && !viewModel?.planType ? 1 : 4} fontWeight={500}>
                            <Trans i18nKey="yourPrivacyPrice" values={{ units: data?.numberOfLegalEntities, price: totalPrice }}></Trans>
                        </DotLegalHeader>
                    </Box>

                    {errors.requiredField && !viewModel?.planType && (
                        <DotLegalHeader headerStyle={"small"} marginBottom={2} fontWeight={500}>
                            <span style={styles.error}>{translateString("planSelectionRequired")}</span>
                        </DotLegalHeader>
                    )}

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <DotLegalTextField
                                disabled
                                label={translateString("companyName")}
                                value={viewModel?.companyName!}
                                debounce={false}
                                noMargin
                                errorText={errors.requiredField && !viewModel?.companyName ? errors.requiredField : ""}
                                onChange={(companyName) => {
                                    const tempModel = { ...viewModel! };
                                    tempModel.companyName = companyName;
                                    setViewModel(tempModel);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DotLegalTextField
                                label={translateString("legalAddress")}
                                value={viewModel?.Address!}
                                debounce={false}
                                noMargin
                                errorText={errors.requiredField && !viewModel?.Address ? errors.requiredField : ""}
                                onChange={(address) => {
                                    const tempModel = { ...viewModel! };
                                    tempModel.Address = address;
                                    setViewModel(tempModel);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DotLegalTextField
                                label={translateString("legalZipCode")}
                                value={viewModel?.postalCode!}
                                debounce={false}
                                noMargin
                                errorText={errors.requiredField && !viewModel?.postalCode ? errors.requiredField : ""}
                                onChange={(postalCode) => {
                                    const tempModel = { ...viewModel! };
                                    tempModel.postalCode = postalCode;
                                    setViewModel(tempModel);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DotLegalTextField
                                label={translateString("legalCity")}
                                value={viewModel?.city!}
                                debounce={false}
                                noMargin
                                errorText={errors.requiredField && !viewModel?.city ? errors.requiredField : ""}
                                onChange={(city) => {
                                    const tempModel = { ...viewModel! };
                                    tempModel.city = city;
                                    setViewModel(tempModel);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DotLegalSelect
                                options={GetCountriesSelectOptions(translateCountry)}
                                placeholder={translateString("legalCountry")}
                                label={translateString("legalCountry")}
                                selectedItem={viewModel?.countryCode}
                                noMargin
                                errorText={errors.requiredField && !viewModel?.country ? errors.requiredField : ""}
                                onChange={(countryCode) => {
                                    const tempModel = { ...viewModel! };
                                    tempModel.country = countryCode ? translateCountry(countryCode) : undefined;
                                    tempModel.countryCode = countryCode ?? undefined;

                                    setViewModel(tempModel);
                                }}
                                noOptionsLabel={translateString("noOptions")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DotLegalTextField
                                placeholder="DK12345678"
                                label={translateString("legalVATNumber")}
                                value={viewModel?.legalVATNumber!}
                                debounce={false}
                                noMargin
                                onChange={(vatNumber) => {
                                    const tempModel = { ...viewModel! };
                                    tempModel.legalVATNumber = vatNumber;
                                    setViewModel(tempModel);
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={styles.recipientContainer}></Box>

                    <Box sx={styles.recipentHeader}>
                        <DotLegalHeader headerStyle={"small"} fontWeight={700} marginBottom={1}>
                            {translateString("InvoiceInformation")}
                        </DotLegalHeader>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <DotLegalTextField
                                label={translateString("billingName")}
                                noMargin
                                value={viewModel?.fullName!}
                                debounce={false}
                                errorText={errors.requiredField && !viewModel?.fullName ? errors.requiredField : ""}
                                onChange={(name) => {
                                    const tempModel = { ...viewModel! };
                                    tempModel.fullName = name;
                                    setViewModel(tempModel);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DotLegalTextField
                                label={translateString("billingEmail")}
                                value={viewModel?.email!}
                                debounce={false}
                                noMargin
                                errorText={errors.emailError ? errors.emailError : ""}
                                onChange={(email) => {
                                    const tempModel = { ...viewModel! };
                                    tempModel.email = email;
                                    validateEmailAddress(email);
                                    setViewModel(tempModel);
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* <Box sx={styles.recipientContainer}></Box>

                    <Box sx={styles.recipentHeader}>
                        <DotLegalHeader headerStyle={"small"} fontWeight={700} marginBottom={1}>
                            {translateString("paymentType")}
                        </DotLegalHeader>
                    </Box> */}

                    {/*                     <RadioGroup
                        sx={styles.radioGroup}
                        row
                        value={viewModel?.paymentMethod ?? 1}
                        onChange={(e) => {
                            const tempModel = { ...viewModel! };
                            tempModel.paymentMethod = Number((e.target as HTMLInputElement).value);
                            setViewModel(tempModel);
                        }}
                    >
                        <DotLegalRadioButton labelPlacement="end" value={PaymentMethod.Card} label={translateString("card")} />
                        <DotLegalRadioButton labelPlacement="end" value={PaymentMethod.Invoice} label={translateString("invoice")} />
                    </RadioGroup> */}

                    <DotLegalHeader headerStyle={"small"} marginBottom={2} marginTop={3}>
                        <a href={translateString("privacyPlanListLink")} target={"_blank"} rel="noreferrer" style={styles.planLink}>
                            {translateString("privacyPlanListLinkTextExtended")}
                        </a>
                    </DotLegalHeader>

                    <DotLegalButton buttonType={"primary"} isLoading={isSaving} disabled={isSaving} onClick={() => validatePurchaseFormForm()}>
                        {translateString("completePurchase")}
                    </DotLegalButton>

                    <DotLegalHeader marginTop={2} headerStyle="extraSmall" fontWeight={500}>
                        <Trans
                            i18nKey={"privacyAcceptanceOnPurchase"}
                            components={{
                                terms: (
                                    <a href="https://www.dotlegal.com/en/terms-of-service" target={"_blank"} rel="noreferrer" style={styles.planLink}>
                                        Terms of Service
                                    </a>
                                ),
                                privacy: (
                                    <a href="https://www.dotlegal.com/en/privacy-policy" target={"_blank"} rel="noreferrer" style={styles.planLink}>
                                        Privacy Policy
                                    </a>
                                ),
                            }}
                        />
                    </DotLegalHeader>
                </>
            )}
        </Box>
    );
}

export default PurchaseForm;

// TODO i fremtiden skal dette evt ligges backend eller anden fil
function GetCountriesSelectOptions(translateCountry: any) {
    let countries = [];

    countries.push({ id: "AF", name: translateCountry("AF") });
    countries.push({ id: "AL", name: translateCountry("AL") });
    countries.push({ id: "DZ", name: translateCountry("DZ") });
    countries.push({ id: "AS", name: translateCountry("AS") });
    countries.push({ id: "AD", name: translateCountry("AD") });
    countries.push({ id: "AO", name: translateCountry("AO") });
    countries.push({ id: "AI", name: translateCountry("AI") });
    countries.push({ id: "AQ", name: translateCountry("AQ") });
    countries.push({ id: "AG", name: translateCountry("AG") });
    countries.push({ id: "AR", name: translateCountry("AR") });
    countries.push({ id: "AM", name: translateCountry("AM") });
    countries.push({ id: "AW", name: translateCountry("AW") });
    countries.push({ id: "AZ", name: translateCountry("AZ") });
    countries.push({ id: "AU", name: translateCountry("AU") });
    countries.push({ id: "BS", name: translateCountry("BS") });
    countries.push({ id: "BH", name: translateCountry("BH") });
    countries.push({ id: "BD", name: translateCountry("BD") });
    countries.push({ id: "BB", name: translateCountry("BB") });
    countries.push({ id: "BE", name: translateCountry("BE") });
    countries.push({ id: "BZ", name: translateCountry("BZ") });
    countries.push({ id: "BJ", name: translateCountry("BJ") });
    countries.push({ id: "BM", name: translateCountry("BM") });
    countries.push({ id: "BT", name: translateCountry("BT") });
    countries.push({ id: "BO", name: translateCountry("BO") });
    countries.push({ id: "BA", name: translateCountry("BA") });
    countries.push({ id: "BW", name: translateCountry("BW") });
    countries.push({ id: "BR", name: translateCountry("BR") });
    countries.push({ id: "VG", name: translateCountry("VG") });
    countries.push({ id: "BN", name: translateCountry("BN") });
    countries.push({ id: "BG", name: translateCountry("BG") });
    countries.push({ id: "BF", name: translateCountry("BF") });
    countries.push({ id: "MM", name: translateCountry("MM") });
    countries.push({ id: "BI", name: translateCountry("BI") });
    countries.push({ id: "KH", name: translateCountry("KH") });
    countries.push({ id: "CM", name: translateCountry("CM") });
    countries.push({ id: "CA", name: translateCountry("CA") });
    countries.push({ id: "KY", name: translateCountry("KY") });
    countries.push({ id: "CF", name: translateCountry("CF") });
    countries.push({ id: "CL", name: translateCountry("CL") });
    countries.push({ id: "CO", name: translateCountry("CO") });
    countries.push({ id: "KM", name: translateCountry("KM") });
    countries.push({ id: "CK", name: translateCountry("CK") });
    countries.push({ id: "CR", name: translateCountry("CR") });
    countries.push({ id: "CU", name: translateCountry("CU") });
    countries.push({ id: "CY", name: translateCountry("CY") });
    countries.push({ id: "CW", name: translateCountry("CW") });
    countries.push({ id: "DK", name: translateCountry("DK") });
    countries.push({ id: "CD", name: translateCountry("CD") });
    countries.push({ id: "DO", name: translateCountry("DO") });
    countries.push({ id: "DJ", name: translateCountry("DJ") });
    countries.push({ id: "DM", name: translateCountry("DM") });
    countries.push({ id: "EC", name: translateCountry("EC") });
    countries.push({ id: "EG", name: translateCountry("EG") });
    countries.push({ id: "SV", name: translateCountry("SV") });
    countries.push({ id: "CI", name: translateCountry("CI") });
    countries.push({ id: "ER", name: translateCountry("ER") });
    countries.push({ id: "EE", name: translateCountry("EE") });
    countries.push({ id: "ET", name: translateCountry("ET") });
    countries.push({ id: "FK", name: translateCountry("FK") });
    countries.push({ id: "FJ", name: translateCountry("FJ") });
    countries.push({ id: "PH", name: translateCountry("PH") });
    countries.push({ id: "FI", name: translateCountry("FI") });
    countries.push({ id: "AE", name: translateCountry("AE") });
    countries.push({ id: "US", name: translateCountry("US") });
    countries.push({ id: "FR", name: translateCountry("FR") });
    countries.push({ id: "PF", name: translateCountry("PF") });
    countries.push({ id: "FO", name: translateCountry("FO") });
    countries.push({ id: "GA", name: translateCountry("GA") });
    countries.push({ id: "GM", name: translateCountry("GM") });
    countries.push({ id: "GE", name: translateCountry("GE") });
    countries.push({ id: "GH", name: translateCountry("GH") });
    countries.push({ id: "GI", name: translateCountry("GI") });
    countries.push({ id: "GD", name: translateCountry("GD") });
    countries.push({ id: "GR", name: translateCountry("GR") });
    countries.push({ id: "GL", name: translateCountry("GL") });
    countries.push({ id: "GU", name: translateCountry("GU") });
    countries.push({ id: "GT", name: translateCountry("GT") });
    countries.push({ id: "GN", name: translateCountry("GN") });
    countries.push({ id: "GW", name: translateCountry("GW") });
    countries.push({ id: "GY", name: translateCountry("GY") });
    countries.push({ id: "HT", name: translateCountry("HT") });
    countries.push({ id: "NL", name: translateCountry("NL") });
    countries.push({ id: "HN", name: translateCountry("HN") });
    countries.push({ id: "HK", name: translateCountry("HK") });
    countries.push({ id: "BY", name: translateCountry("BY") });
    countries.push({ id: "IN", name: translateCountry("IN") });
    countries.push({ id: "ID", name: translateCountry("ID") });
    countries.push({ id: "IR", name: translateCountry("IR") });
    countries.push({ id: "IQ", name: translateCountry("IQ") });
    countries.push({ id: "IE", name: translateCountry("IE") });
    countries.push({ id: "IS", name: translateCountry("IS") });
    countries.push({ id: "IM", name: translateCountry("IM") });
    countries.push({ id: "IL", name: translateCountry("IL") });
    countries.push({ id: "IT", name: translateCountry("IT") });
    countries.push({ id: "JM", name: translateCountry("JM") });
    countries.push({ id: "JP", name: translateCountry("JP") });
    countries.push({ id: "JE", name: translateCountry("JE") });
    countries.push({ id: "JO", name: translateCountry("JO") });
    countries.push({ id: "GF", name: translateCountry("GF") });
    countries.push({ id: "CV", name: translateCountry("CV") });
    countries.push({ id: "KZ", name: translateCountry("KZ") });
    countries.push({ id: "KE", name: translateCountry("KE") });
    countries.push({ id: "CN", name: translateCountry("CN") });
    countries.push({ id: "KG", name: translateCountry("KG") });
    countries.push({ id: "KI", name: translateCountry("KI") });
    countries.push({ id: "HR", name: translateCountry("HR") });
    countries.push({ id: "KW", name: translateCountry("KW") });
    countries.push({ id: "LA", name: translateCountry("LA") });
    countries.push({ id: "LS", name: translateCountry("LS") });
    countries.push({ id: "LV", name: translateCountry("LV") });
    countries.push({ id: "LB", name: translateCountry("LB") });
    countries.push({ id: "LR", name: translateCountry("LR") });
    countries.push({ id: "LY", name: translateCountry("LY") });
    countries.push({ id: "LI", name: translateCountry("LI") });
    countries.push({ id: "LT", name: translateCountry("LT") });
    countries.push({ id: "LU", name: translateCountry("LU") });
    countries.push({ id: "MO", name: translateCountry("MO") });
    countries.push({ id: "MG", name: translateCountry("MG") });
    countries.push({ id: "MK", name: translateCountry("MK") });
    countries.push({ id: "MW", name: translateCountry("MW") });
    countries.push({ id: "MY", name: translateCountry("MY") });
    countries.push({ id: "MV", name: translateCountry("MV") });
    countries.push({ id: "ML", name: translateCountry("ML") });
    countries.push({ id: "MT", name: translateCountry("MT") });
    countries.push({ id: "MA", name: translateCountry("MA") });
    countries.push({ id: "MH", name: translateCountry("MH") });
    countries.push({ id: "MR", name: translateCountry("MR") });
    countries.push({ id: "MU", name: translateCountry("MU") });
    countries.push({ id: "YT", name: translateCountry("YT") });
    countries.push({ id: "MX", name: translateCountry("MX") });
    countries.push({ id: "FM", name: translateCountry("FM") });
    countries.push({ id: "MD", name: translateCountry("MD") });
    countries.push({ id: "MC", name: translateCountry("MC") });
    countries.push({ id: "MN", name: translateCountry("MN") });
    countries.push({ id: "ME", name: translateCountry("ME") });
    countries.push({ id: "MS", name: translateCountry("MS") });
    countries.push({ id: "MZ", name: translateCountry("MZ") });
    countries.push({ id: "NA", name: translateCountry("NA") });
    countries.push({ id: "NR", name: translateCountry("NR") });
    countries.push({ id: "NP", name: translateCountry("NP") });
    countries.push({ id: "NZ", name: translateCountry("NZ") });
    countries.push({ id: "NI", name: translateCountry("NI") });
    countries.push({ id: "NF", name: translateCountry("NF") });
    countries.push({ id: "NE", name: translateCountry("NE") });
    countries.push({ id: "NG", name: translateCountry("NG") });
    countries.push({ id: "NU", name: translateCountry("NU") });
    countries.push({ id: "KP", name: translateCountry("KP") });
    countries.push({ id: "MP", name: translateCountry("MP") });
    countries.push({ id: "NO", name: translateCountry("NO") });
    countries.push({ id: "NC", name: translateCountry("NC") });
    countries.push({ id: "OM", name: translateCountry("OM") });
    countries.push({ id: "PK", name: translateCountry("PK") });
    countries.push({ id: "PW", name: translateCountry("PW") });
    countries.push({ id: "PA", name: translateCountry("PA") });
    countries.push({ id: "PG", name: translateCountry("PG") });
    countries.push({ id: "PY", name: translateCountry("PY") });
    countries.push({ id: "VA", name: translateCountry("VA") });
    countries.push({ id: "PE", name: translateCountry("PE") });
    countries.push({ id: "PN", name: translateCountry("PN") });
    countries.push({ id: "PL", name: translateCountry("PL") });
    countries.push({ id: "PT", name: translateCountry("PT") });
    countries.push({ id: "PR", name: translateCountry("PR") });
    countries.push({ id: "QA", name: translateCountry("QA") });
    countries.push({ id: "CG", name: translateCountry("CG") });
    countries.push({ id: "RO", name: translateCountry("RO") });
    countries.push({ id: "RU", name: translateCountry("RU") });
    countries.push({ id: "RW", name: translateCountry("RW") });
    countries.push({ id: "BL", name: translateCountry("BL") });
    countries.push({ id: "SH", name: translateCountry("SH") });
    countries.push({ id: "KN", name: translateCountry("KN") });
    countries.push({ id: "LC", name: translateCountry("LC") });
    countries.push({ id: "MF", name: translateCountry("MF") });
    countries.push({ id: "PM", name: translateCountry("PM") });
    countries.push({ id: "VC", name: translateCountry("VC") });
    countries.push({ id: "SB", name: translateCountry("SB") });
    countries.push({ id: "WS", name: translateCountry("WS") });
    countries.push({ id: "SM", name: translateCountry("SM") });
    countries.push({ id: "ST", name: translateCountry("ST") });
    countries.push({ id: "SA", name: translateCountry("SA") });
    countries.push({ id: "CH", name: translateCountry("CH") });
    countries.push({ id: "SN", name: translateCountry("SN") });
    countries.push({ id: "RS", name: translateCountry("RS") });
    countries.push({ id: "SC", name: translateCountry("SC") });
    countries.push({ id: "SL", name: translateCountry("SL") });
    countries.push({ id: "SG", name: translateCountry("SG") });
    countries.push({ id: "SK", name: translateCountry("SK") });
    countries.push({ id: "SI", name: translateCountry("SI") });
    countries.push({ id: "SO", name: translateCountry("SO") });
    countries.push({ id: "ES", name: translateCountry("ES") });
    countries.push({ id: "LK", name: translateCountry("LK") });
    countries.push({ id: "GG", name: translateCountry("GG") });
    countries.push({ id: "GP", name: translateCountry("GP") });
    countries.push({ id: "SD", name: translateCountry("SD") });
    countries.push({ id: "SR", name: translateCountry("SR") });
    countries.push({ id: "SJ", name: translateCountry("SJ") });
    countries.push({ id: "SE", name: translateCountry("SE") });
    countries.push({ id: "SZ", name: translateCountry("SZ") });
    countries.push({ id: "ZA", name: translateCountry("ZA") });
    countries.push({ id: "KR", name: translateCountry("KR") });
    countries.push({ id: "SY", name: translateCountry("SY") });
    countries.push({ id: "TJ", name: translateCountry("TJ") });
    countries.push({ id: "TW", name: translateCountry("TW") });
    countries.push({ id: "TZ", name: translateCountry("TZ") });
    countries.push({ id: "TD", name: translateCountry("TD") });
    countries.push({ id: "TH", name: translateCountry("TH") });
    countries.push({ id: "TL", name: translateCountry("TL") });
    countries.push({ id: "CZ", name: translateCountry("CZ") });
    countries.push({ id: "TG", name: translateCountry("TG") });
    countries.push({ id: "TK", name: translateCountry("TK") });
    countries.push({ id: "TO", name: translateCountry("TO") });
    countries.push({ id: "TT", name: translateCountry("TT") });
    countries.push({ id: "TN", name: translateCountry("TN") });
    countries.push({ id: "TM", name: translateCountry("TM") });
    countries.push({ id: "TC", name: translateCountry("TC") });
    countries.push({ id: "TV", name: translateCountry("TV") });
    countries.push({ id: "TR", name: translateCountry("TR") });
    countries.push({ id: "DE", name: translateCountry("DE") });
    countries.push({ id: "UG", name: translateCountry("UG") });
    countries.push({ id: "UA", name: translateCountry("UA") });
    countries.push({ id: "UK", name: translateCountry("UK") });
    countries.push({ id: "HU", name: translateCountry("HU") });
    countries.push({ id: "UY", name: translateCountry("UY") });
    countries.push({ id: "VI", name: translateCountry("VI") });
    countries.push({ id: "UZ", name: translateCountry("UZ") });
    countries.push({ id: "VU", name: translateCountry("VU") });
    countries.push({ id: "VE", name: translateCountry("VE") });
    countries.push({ id: "EH", name: translateCountry("EH") });
    countries.push({ id: "VN", name: translateCountry("VN") });
    countries.push({ id: "WF", name: translateCountry("WF") });
    countries.push({ id: "YE", name: translateCountry("YE") });
    countries.push({ id: "ZM", name: translateCountry("ZM") });
    countries.push({ id: "ZW", name: translateCountry("ZW") });
    countries.push({ id: "GQ", name: translateCountry("GQ") });
    countries.push({ id: "AT", name: translateCountry("AT") });

    return countries;
}
